import React, { useMemo } from 'react';
import { format } from "date-fns";
import {
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    makeStyles,
    Box,
} from "@material-ui/core";
import {
    ArrowDropDown,
    ArrowDropUp,   
} from "@material-ui/icons";

import {    
    useGetMetricsQuery,
} from "../../store/slices/pardotApiSlice";
const useStyles = makeStyles({
    table:
    {
        width: 'auto',
        '& td:not(:first-child),th:not(:first-child)': {
            textAlign: 'center'
        }
    },
    minor: {
        fontSize: '0.8em'
    }
});

const Counts = () => {

    const classes = useStyles();
    
    const { data: metrics, isFetching: isFetchingMetrics } = useGetMetricsQuery();  

    const months = useMemo(() => {

        if (!metrics)
            return [];

        let list = [...new Set(metrics.map(m => m.month))].sort();

        return list;
    }, [metrics]);

    const profiles = useMemo(() => {

        if (!metrics)
            return [];

        let list = [...new Set(metrics.map(m => m.key))];
        list = list.filter(x => x.startsWith("Engaged.Profile"))
        list = list.map(x => x.replace("Engaged.Profile.Total.", ""));
        list.sort();        

        return list;
    }, [metrics]);

    const triages = useMemo(() => {

        if (!metrics)
            return [];

        let list = [...new Set(metrics.map(m => m.key))];
        list = list.filter(x => x.includes("Triage") && !x.endsWith(".New"));
        list = list.map(x => x.replace("Distributed.Triage.", "").replace("Remaining.Triage.", ""));
        list = [...new Set(list)];
        list.sort();

        console.log({list});

        return list;
    }, [metrics]);

    const lookup = (month, key) => {
        var entry = metrics.find(x => x.month === month && x.key === key);
        return entry?.value;
    }

    const trendInfo = (m, key) => {

        var current = lookup(m, key);

        let date = new Date(m);

        date.setMonth(date.getMonth() - 1);

        let previous = lookup(format(date, "yyyy-MM-dd'T'HH:mm:ss"), key);

        if (!previous)
            return null;

        let difference = current - previous;
        let percent = difference * 100 / previous;

        if (difference > 0)
            return (
                <Box display="flex" alignItems="center" style={{ color: 'green' }}>
                    <ArrowDropUp />
                    <Box>{percent.toFixed(1)}%</Box>
                </Box>
            );

        if (difference < 0)
            return (
                <Box display="flex" alignItems="center" style={{ color: 'red' }}>
                    <ArrowDropDown />
                    <Box>{percent.toFixed(1)}%</Box>
                </Box>
            );

        return "--";
    }    

    return (
        <>           
            {isFetchingMetrics && <h2 style={{ margin: '10px' }}>Loading Metrics...</h2>}
            {!isFetchingMetrics &&
                <Box display="flex" gridGap="50px">
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {months.map(m => <TableCell key={m}>{format(new Date(m), "MMMM")}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <div>Total Prospect Engaged</div>
                                    {profiles.map(p =>
                                        <div className={classes.minor} key={p} style={{ marginLeft: '20px' }}>{p || "unknown"}</div>
                                    )}
                                </TableCell>
                                {months.map(m =>
                                    <TableCell key={m}>
                                        <Box display="flex" alignItems="center">
                                            <Box>{lookup(m, "Engaged.Total")}</Box>
                                            <Box>{trendInfo(m, `Engaged.Total`)}</Box>
                                        </Box>

                                        {profiles.map(p =>
                                            <div key={p} className={classes.minor}>{lookup(m, `Engaged.Profile.Total.${p}`) || 0}</div>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow>
                                <TableCell>Total Reengaged Prospects</TableCell>
                                {months.map(m => <TableCell key={m}>{lookup(m, "Engaged.Existing")}</TableCell>)}
                            </TableRow>
                            <TableRow>
                                <TableCell>Total New Prospects Engaged</TableCell>
                                {months.map(m => <TableCell key={m}>{lookup(m, "Engaged.New")}</TableCell>)}
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <div>Total Distributed</div>
                                    {profiles.map(p =>
                                        <div className={classes.minor} key={p} style={{ marginLeft: '20px' }}>{p || "unknown"}</div>
                                    )}
                                </TableCell>
                                {months.map(m =>
                                    <TableCell key={m}>
                                        <Box display="flex" alignItems="center">
                                            <Box>{lookup(m, "Distributed.Total")}</Box>
                                            <Box>{trendInfo(m, `Distributed.Total`)}</Box>
                                        </Box>
                                        {profiles.map(p =>
                                            <div key={p} className={classes.minor}>{lookup(m, `Distributed.Profile.${p}`) || 0}</div>
                                        )}
                                    </TableCell>
                                )}
                            </TableRow>
                            <TableRow>
                                <TableCell>Distribution Percent</TableCell>
                                {months.map(m => <TableCell key={m}>{lookup(m, "Distributed.Percent")}%</TableCell>)}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Remaining Split</TableCell>
                                {months.map(m => <TableCell key={m}>{format(new Date(m), "MMMM")}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {triages.map(t =>
                                <TableRow key={t}>
                                    <TableCell>{t}</TableCell>
                                    {months.map(m => <TableCell key={m}>{lookup(m, `Remaining.Triage.${t}`) && <>{lookup(m, `Remaining.Triage.${t}`)} ({lookup(m, `Remaining.Triage.${t}.New`)})</>}</TableCell>)}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>          
                </Box>                
            }            
        </>
    )
};

export default Counts;
